<template>
  <div class="relative flex flex-col min-h-screen">
    <main
      ref="mainElement"
      :class="['flex-grow flex justify-center items-start', backgroundClass]"
    >
      <div class="w-full">
        <NuxtPage />
      </div>
    </main>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed, provide } from 'vue';
import { setContentHeight } from '~/composables/useContentHeight';
import eventBus from '~/eventBus';
import { useRender } from '~/composables/useRender';

const { updateStatus } = useRender();
const background = ref(null);

function handleBackgroundUpdate(value) {
  background.value = value;
}

const mainElement = ref(null);

onMounted(() => {
  // Register the event listener early
  eventBus.on('updateBackground', handleBackgroundUpdate);

  // Observe the height of the main element
  const updateMainElementHeight = () => {
    if (mainElement.value) {
      setContentHeight(mainElement.value.offsetHeight);
    }
  };

  updateMainElementHeight(); // Initial update
  const resizeObserver = new ResizeObserver(() => updateMainElementHeight());
  if (mainElement.value) resizeObserver.observe(mainElement.value);

  updateStatus(true);

  // Cleanup listener and observer
  onUnmounted(() => {
    eventBus.off('updateBackground', handleBackgroundUpdate);
    resizeObserver.disconnect();
  });
});

// Provide the debug state
const showDebug = ref(false);
provide('showDebug', showDebug);

// Compute the background class dynamically
const backgroundClass = computed(() => (background.value ? `${background.value}` : 'bg-white'));
</script>

<style>
body {
  touch-action: manipulation;
}
.toggle-debug-btn {
  position: fixed;
  bottom: 20px;
  right: 70px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 999;
}
</style>
